<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="addItemForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Artikl</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row>
                <v-col>
                  <v-textarea
                    rows="2"
                    dense
                    class="pt-2"
                    outlined
                    label="Naziv"
                    autofocus
                    v-model="name"
                    :rules="[rules.req]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="pt-0 pb-0 mt-0 mb-0">
                <v-col class="pt-0 pb-4 mt-0 mb-0">
                  <v-text-field
                    dense
                    hide-details
                    outlined
                    label="Barkodovi sa zarezom"
                    v-model="barcode"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-4 mt-0 mb-0">
                  <v-text-field
                    dense
                    outlined
                    label="Šifra"
                    v-model="code"
                    @input="checkCodeAvailability"
                    validate-on-blur
                    :rules="[rules.codeused]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-0 pb-0 mt-0 mb-0">
                <v-col class="pt-0 pb-0 mt-0 mb-0">
                  <v-select
                    dense
                    outlined
                    label="Vrsta artikla"
                    v-model="type"
                    item-text="name"
                    return-object
                    :items="itemTypes"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
                <v-col class="pt-0 pb-0 mt-0 mb-0">
                  <v-select
                    dense
                    outlined
                    label="Porezna grupa"
                    v-model="selectedTaxGroup"
                    item-text="text"
                    item-value="value"
                    :items="taxGroups"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="pt-0 pb-0 mt-0 mb-0 flex-wrap justify-center">
                <div style="width: 70%" class="pl-3 pr-2 pa-0 ma-0">
                  <v-select
                    dense
                    outlined
                    label="Dobavljač"
                    v-model="supplier"
                    item-text="name"
                    return-object
                    :items="suppliers"
                  ></v-select>
                </div>
                <div style="width: 30%" class="pa-0 pr-3 ma-0">
                  <v-btn
                    x-small
                    class="white--text okButton"
                    style="width: 100%"
                    height="40"
                    @click="openSupplier()"
                    :disabled="supplier === undefined ? true : false"
                    >Uredi dobavljača</v-btn
                  >
                </div>
              </v-row>
              <v-row
                v-if="itemSuppliers !== undefined && itemSuppliers.length > 0"
                class="pt-0 mt-0 flex-wrap justify-center"
              >
                <v-col class="pt-0 mt-0 pb-2 mb-5">
                  <v-data-table
                    v-if="
                      itemSuppliers !== undefined && itemSuppliers.length > 0
                    "
                    :headers="itemSupplierHeader"
                    :items="itemSuppliers"
                    class="elevation-3"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template v-slot:body="{ items, headers }">
                      <tbody>
                        <tr v-for="(item, idx) in items" :key="idx">
                          <td v-for="(header, key) in headers" :key="key">
                            <v-edit-dialog
                              :return-value.sync="item[header.value]"
                            >
                              {{ item[header.value] }}
                              <template v-slot:input>
                                <v-text-field
                                  v-if="headers.length - 1 !== key"
                                  v-model="item[header.value]"
                                  label="Uredi"
                                  single-line
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                            <v-icon
                              class="pb-4"
                              small
                              v-if="headers.length - 1 === key"
                              @click="removeSupplier(itemSuppliers[idx])"
                            >
                              mdi-delete
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-text-field
                  class="pa-0"
                  v-if="warehouseModule"
                  outlined
                  hide-details
                  dense
                  :rules="[rules.req]"
                  v-model="whStorageUnit"
                  label="Osnovna jedinica"
                >
                </v-text-field>
                <v-text-field
                  class="pa-0 pl-2"
                  v-if="warehouseModule"
                  outlined
                  dense
                  hide-details
                  v-model="sellingUnit"
                  label="Jedinica za prodaju"
                >
                </v-text-field>
                <v-checkbox
                  class="pl-2"
                  v-model="whSellingItem"
                  outlined
                  dense
                  v-if="warehouseModule"
                  label="Prodajna jedinica"
                >
                </v-checkbox>
              </v-row>
              <!-- <v-row class="ma-0 pa-0 pb-2">
                <v-col class="pl-0 pt-0 pb-0" cols="4">
                  <v-text-field
                    class="pa-0 pl-0"
                    v-if="warehouseModule"
                    outlined
                    dense
                    hide-details
                    :rules="[rules.req]"
                    v-model="sellingUnit"
                    label="Jedinica za prodaju"
                  >
                  </v-text-field>
                </v-col>
              </v-row> -->
              <v-row class="pl-3 pa-0 ma-0">
                <v-col class="d-flex justify-center pa-0 ma-0">
                  <v-checkbox
                    v-model="whDefaultSale"
                    class="pa-0 ma-0"
                    outlined
                    v-if="warehouseModule"
                    @change="changeDefaultSale(whDefaultSale)"
                    label="Zadana prodajna jedinica"
                  >
                  </v-checkbox>
                  <v-checkbox
                    v-model="whDefaultStore"
                    class="pa-0 pl-1 ma-0"
                    outlined
                    v-if="warehouseModule"
                    @change="changeDefaultStore(whDefaultStore)"
                    label="Zadana skladišna jedinica"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row class="justify-center pa-0 ma-0" v-if="!showUnits">
                <v-btn
                  class="pa-0"
                  style="z-index: 8"
                  bottom
                  right
                  plain
                  text
                  v-if="this.warehouseModule"
                  @keyup.enter="openAddUnitForm"
                  @click.stop="openAddUnitForm"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span>Dodaj jedinicu</span></v-btn
                >
              </v-row>
              <v-expansion-panels v-if="showUnits">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row class="d-flex justify-center pa-0" v-if="showUnits">
                      <p class="text-h6 align-center font-weight-bold">
                        Dodatne jedinice mjere
                      </p>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center pa-0">
                      <v-data-table
                        v-if="showUnits"
                        :headers="unitHeaders"
                        :items="unitTableRows"
                        :items-per-page="-1"
                        class="elevation-1"
                        hide-default-footer
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td>{{ item.customItemUnit }}</td>
                            <td>{{ item.customItemAmount }}</td>
                            <td>{{ whStorageUnit }}</td>
                            <td>
                              <v-icon v-if="item.sellingItem === true"
                                >mdi-check</v-icon
                              >
                              <v-icon v-if="item.sellingItem === false"
                                >mdi-close</v-icon
                              >
                            </td>
                            <td>
                              <v-icon v-if="item.defaultSale === true"
                                >mdi-check</v-icon
                              >
                              <v-icon v-if="item.defaultSale === false"
                                >mdi-close</v-icon
                              >
                            </td>
                            <td>
                              <v-icon v-if="item.defaultStore === true"
                                >mdi-check</v-icon
                              >
                              <v-icon v-if="item.defaultStore === false"
                                >mdi-close</v-icon
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-btn
                        class="pa-5 pt-7"
                        style="z-index: 8"
                        bottom
                        right
                        plain
                        text
                        @click="openAddUnitForm"
                      >
                        <v-icon>mdi-plus</v-icon>
                        <span>{{ unitButton }}</span>
                      </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-divider> </v-divider>
              <v-row class="d-flex justify-center pa-0" v-if="!showNorms">
                <v-btn
                  class="pa-0 pt-5"
                  style="z-index: 8"
                  bottom
                  right
                  plain
                  text
                  @click="openAddNormForm"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span>Dodaj normativ</span>
                </v-btn>
              </v-row>
              <v-expansion-panels v-if="showNorms">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row class="d-flex justify-center pa-0" v-if="showNorms">
                      <p class="text-h6 align-center font-weight-bold">
                        Normativ
                      </p>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center pa-0">
                      <v-data-table
                        v-if="showNorms"
                        :headers="headers"
                        :items="tableRows"
                        :items-per-page="-1"
                        class="elevation-1"
                        hide-default-footer
                      >
                      </v-data-table>
                    </v-row>
                    <v-row class="d-flex justify-center pa-0">
                      <v-btn
                        class="pa-5 pt-7"
                        style="z-index: 8"
                        bottom
                        right
                        plain
                        text
                        @click="openAddNormForm"
                      >
                        <v-icon>mdi-plus</v-icon>
                        <span>{{ normButton }}</span>
                      </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" class="pt-0 ma-0">
              <v-row class="justify-center pa-0 ma-0">
                <v-col class="justify-center" cols="3">
                  <v-img
                    ref="itemImg"
                    :src="itemImg"
                    contain
                    height="123px"
                    width="124px"
                    :lazy-src="
                      itemImg ? require('@/assets/no-item-small.jpg') : ''
                    "
                  >
                    <template v-slot:placeholder>
                      <v-img
                        :src="require('@/assets/no-item-small.jpg')"
                      ></v-img>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="6">
                  <v-file-input
                    ref="imageUpl"
                    label="Odaberi sliku artikla"
                    style="overflow: hidden"
                    @change="onFileChange"
                    clearable
                    @click:close="onFileChange"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting || isExistingCode"
            type="submit"
          >
            Dodaj artikl
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <add-norm ref="addNorm" @add="onAddNorm"></add-norm>
    <add-unit ref="addUnit" @add="onAddUnit"></add-unit>
    <add-item-supplier-codes
      ref="addCodes"
      @add="onAddCodes"
    ></add-item-supplier-codes>
  </v-dialog>
</template>
<script>
import { df, auth, functions } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
// import { clone } from '@/plugins/utils'
// import QuantityInput from '../../../components/QuantityInput.vue'
import AddNorm from '@/modules/company/components/AddNorm'
import AddUnit from '@/modules/company/components/AddUnit'
import AddItemSupplierCodes from '@/modules/company/components//AddItemSupplierCodes'

export default {
  components: { AddNorm, AddUnit, AddItemSupplierCodes },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    submitting: false,
    name: undefined,
    code: undefined,
    barcode: undefined,
    type: undefined,
    itemTypes: [],
    newUnits: [],
    storeUnit: undefined,
    storageUnit: undefined,
    storageUnits: [],
    sellingUnit: 'kom',
    sellingUnits: [],
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    checking: false,
    selectedLocations: [],
    locations: [],
    itemImg: '',
    warehouseModule: false,
    whStorageUnit: undefined,
    whSellingItem: true,
    whDefaultSale: true,
    whDefaultStore: true,
    itemCode: undefined,
    supplierDialog: false,
    listeners: [],
    items: [],
    tableRows: [],
    unitTableRows: [],
    headers: [{
      text: 'Artikl',
      value: 'name'
    },
    {
      text: 'Količina',
      value: 'quantity'
    }],
    unitHeaders: [{
      text: 'Mjerna jedinica',
      value: 'customItemUnit'
    },
    {
      text: 'Količina',
      value: 'customItemAmount'
    },
    {
      text: 'Osnovna mjerna jedinica',
      value: 'whStorageUnit'
    },
    {
      text: 'Prodajna jedinica',
      value: 'sellingItem'
    },
    {
      text: 'Zadana prodajna jedinica',
      value: 'defaultSale'
    },
    {
      text: 'Zadana skladišna jedinica',
      value: 'defaultStore'
    }],
    isExistingCode: false,
    normItemSelected: undefined,
    norms: [],
    showNorms: false,
    showUnits: false,
    selectedNormType: undefined,
    supplier: undefined,
    itemSuppliers: [],
    suppliers: [],
    sortedSuppliers: [],
    itemSupplierHeader: [{
      text: 'Ime dobavljača',
      value: 'name'
    },
    {
      text: 'Šifra kod dobavljača',
      value: 'item_code'
    },
    {
      text: 'Ukloni',
      value: 'delete'
    }],
    taxGroups: [{
      text: 'Hrana - 13%',
      value: 'hrana'
    },
    {
      text: 'Piće - 25%',
      value: 'pice'
    },
    {
      text: 'Ostalo - 0%',
      value: 'ostalo-0'
    },
    {
      text: 'Ostalo - 5%',
      value: 'ostalo-5'
    },
    {
      text: 'Ostalo - 13%',
      value: 'ostalo-13'
    },
    {
      text: 'Ostalo - 25%',
      value: 'ostalo-25'
    }],
    selectedTaxGroup: undefined,
    addedOverWh: false
  }),
  async mounted () {
    var query = df.collection('suppliers').where('company_ids', 'array-contains', state.getCurrentCompany().id)

    var listener = query
      .onSnapshot((doc) => {
        this.suppliers = []
        doc.docs.forEach((rec) => {
          this.suppliers.push(rec.data())
        })
      })

    this.suppliers = this.suppliers.sort((a, b) => a.name.localeCompare(b.name))
    this.listeners.push(listener)

    this.getCompItems()
    this.getMultiplier()
    if (state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModule = true
    }

    this.itemTypes = [
      { name: this.$t('$vuetify.lager.itemTypes.goods'), value: 'GOODS' },
      { name: this.$t('$vuetify.lager.itemTypes.service'), value: 'SERVICE' }
    ]

    this.storageUnits = [
      { name: 'Komad', value: 'komad' },
      { name: 'Kilogram', value: 'kilogram' },
      { name: 'Litra', value: 'litra' },
      { name: 'Sat', value: 'sat' },
      { name: 'm2', value: 'm2' }
    ]

    this.sellingUnits = [
      { name: 'Komad', value: 'komad' },
      { name: 'Kilogram', value: 'kilogram' },
      { name: 'Litra', value: 'litra' },
      { name: 'Sat', value: 'sat' },
      { name: 'm2', value: 'm2' }
    ]

    this.rules.codeused = (val) => {
      return (
        !this.isExistingCode ||
        `Artikl sa šifrom ${val} već postoji.`
      )
    }
  },
  computed: {
    normButton () {
      if (this.norms.length > 0) {
        return 'Uredi'
      } else {
        return 'Dodaj normativ'
      }
    },
    unitButton () {
      if (this.newUnits.length > 0) {
        return 'Uredi'
      } else {
        return 'Dodaj jedinicu'
      }
    }
  },
  methods: {
    async checkCodeAvailability () {
      const code = this.code
      this.checking = true

      if (this.code !== undefined && this.code !== '') {
        const isExistingCode = await df
          .collection(`companies/${state.getCurrentCompany().id}/items`)
          .where('code', '==', code)
          .where('status', '==', 'OK')
          .get()

        this.isExistingCode = !isExistingCode.empty
      } else {
        this.isExistingCode = false
      }
      this.checking = false
    },
    onAddCodes (supplierData) {
      if (supplierData.codes.length > 0) {
        supplierData.codes.forEach(code => {
          this.itemSuppliers.push({ id: this.supplier.id, name: this.supplier.name, type: this.supplier.type, item_code: code, item_codes: [code] })
        })
      }

      this.$forceUpdate()
    },
    openSupplier () {
      this.$refs.addCodes.open(this.supplier, [])
    },
    removeSupplier (supplier) {
      this.itemSuppliers.forEach((supp, key) => {
        if (supp.id === supplier.id) {
          this.itemSuppliers.splice(key, 1)
        }
      })

      const tmp = this.itemSuppliers
      this.itemSuppliers = []
      this.itemSuppliers = JSON.parse(JSON.stringify(tmp))

      this.$forceUpdate()
    },
    addSupplier () {
      if (this.supplier !== undefined && this.itemCode !== undefined) {
        this.itemSuppliers.push({ id: this.supplier.id, name: this.supplier.name, type: this.supplier.type, item_code: this.itemCode, item_codes: [this.itemCode] })
        this.resetSupplierData()
      }
    },
    resetSupplierData () {
      this.itemCode = undefined
      this.supplier = undefined
    },
    onAddUnit (resp) {
      this.newUnits = resp.units
      if (this.newUnits.length > 0) {
        this.showUnits = true
      } else {
        this.showUnits = false
      }

      this.unitTableRows = []

      this.newUnits.forEach(unit => {
        this.unitTableRows.push({ customItemUnit: unit.customItemUnit, customItemAmount: unit.customItemAmount, whStorageUnit: this.whStorageUnit, sellingItem: unit.sellingItem, defaultSale: unit.defaultSale, defaultStore: unit.defaultStore })

        if (unit.defaultStore) {
          this.whDefaultStore = false
        }

        if (unit.defaultSale) {
          this.whDefaultSale = false
        }
      })
    },
    onAddNorm (resp) {
      this.selectedNormType = resp.normType
      this.norms = resp.norms
      if (this.norms.length > 0) {
        this.showNorms = true
      } else {
        this.showNorms = false
      }

      this.tableRows = []

      this.norms.forEach(norm => {
        this.tableRows.push({ name: norm.item.name, quantity: norm.quantity, item: norm.item })
      })
    },
    openAddUnitForm () {
      if (this.newUnits !== undefined && this.newUnits.length > 0) {
        this.$refs.addUnit.open(this.whStorageUnit, this.newUnits)
      } else {
        this.$refs.addUnit.open(this.whStorageUnit, undefined, true)
      }
    },
    openAddNormForm () {
      if (this.norms !== undefined && this.norms.length > 0) {
        this.$refs.addNorm.open(this.item, this.items, this.norms)
      } else {
        this.$refs.addNorm.open(this.item, this.items)
      }
    },
    async getCompItems () {
      const query = await df
        .collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('status', '==', 'OK')

      query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.items.push(doc.data())
          })
        })
    },
    changeDefaultSaleCustom (value, index) {
      if (value) {
        if (this.newUnits.length > 0) {
          this.newUnits.forEach((_, key) => {
            if (key !== index) {
              this.newUnits[key].defaultSale = false
              this.unitTableRows[key].defaultSale = false
            }
          })
        }
        this.whDefaultSale = false
      }
    },
    changeDefaultStoreCustom (value, index) {
      if (value) {
        if (this.newUnits.length > 0) {
          this.newUnits.forEach((_, key) => {
            if (key !== index) {
              this.newUnits[key].defaultStore = false
              this.unitTableRows[key].defaultStore = false
            }
          })
        }
        this.whDefaultStore = false
      }
    },
    changeDefaultSale (value) {
      if (value) {
        if (this.newUnits.length > 0) {
          this.newUnits.forEach((_, key) => {
            this.newUnits[key].defaultSale = false
            this.unitTableRows[key].defaultSale = false
          })
        }
      }
    },
    changeDefaultStore (value) {
      if (value) {
        if (this.newUnits.length > 0) {
          this.newUnits.forEach((_, key) => {
            this.newUnits[key].defaultStore = false
            this.unitTableRows[key].defaultStore = false
          })
        }
      }
    },
    open (addedOverWh = false) {
      this.whDefaultSale = true
      this.whDefaultStore = true
      this.whSellingItem = true
      this.sellingUnit = 'kom'

      this.addedOverWh = addedOverWh
      if (addedOverWh === true) {
        this.whDefaultStore = true
      }
      this.visible = true
      this.type = this.itemTypes[0]
      this.storageUnit = this.storageUnits[0]
      this.submitting = false

      df.doc(
        `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
      )
        .get()
        .then((doc) => {
          if (
            doc.data() &&
            doc.data().locations &&
            Object.keys(doc.data().locations).length > 0
          ) {
            this.locations = Object.keys(doc.data().locations)
              .map((key) => {
                return doc.data().locations[key]
              })
              .filter((location) => location.status === 'OK')

            this.selectedLocations = this.locations.map((location) => {
              return location.id
            })
          }
        })
    },
    close () {
      this.resetInputs()

      this.whStorageUnit = undefined
      this.newUnits = []
      this.unitTableRows = []
      this.tableRows = []
      this.showNorms = false
      this.showUnits = false
      this.addedOverWh = false

      this.$refs.addItemForm.resetValidation()
      this.norms = []
    },
    resetInputs () {
      this.addedOverWh = false
      this.itemImg = ''
      this.name = undefined
      this.code = undefined
      this.barcode = undefined
      this.visible = false
      this.tableRows = []
      this.showNorms = false
      this.newUnits = []
      this.norms = []
      this.$refs.imageUpl.reset()
    },
    createImage (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.itemImg = e.target.result
      }
      reader.readAsDataURL(file)
    },
    onFileChange (file) {
      if (!file) {
        this.itemImg = ''
        return
      }
      if (file.size > 500000) {
        this.showMsgBox({
          text: 'Prevelika datoteka, maksimalna dopuštena veličina datoteke je 500 KB.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.itemImg = ''
        this.$refs.imageUpl.reset()
        return
      }
      this.createImage(file)
    },
    submit () {
      if (!this.$refs.addItemForm.validate()) return
      //   this.showLoader()
      this.submitting = true

      this.itemImg =
        this.itemImg && this.itemImg.split(',').length > 0
          ? this.itemImg.split(',')[1]
          : ''

      if (this.code == null) {
        this.code = ''
      }

      if (this.barcode == null) {
        this.barcode = ''
      }
      const payload = {
        action: {
          operation: 'set',
          entity: 'item',
          params: {
            id: uuidv4(),
            name: this.name.toLowerCase().trim(),
            code: this.code,
            type: this.type.value,
            company_id: state.getCurrentCompany().id,
            storage_unit: this.storageUnit ? this.storageUnit.value : '',
            selling_unit: this.sellingUnit ? this.sellingUnit : '',
            warehouses: this.selectedLocations
          }
        }
      }

      if (this.itemImg && this.itemImg !== '') {
        payload.action.params.picture = this.itemImg
      }

      if (this.selectedTaxGroup) {
        payload.action.params.tax_group = this.selectedTaxGroup
      }

      // Ako je skladiste upaljeno salje se na drugi entitet sa drugim podacima
      if (this.warehouseModule) {
        payload.action.entity = 'warehouse_module_item'
        const units = []
        const mpUnits = new Map()

        const newUuid = uuidv4()
        let defaultStore = ''
        let defaultSale = ''

        const baseUnit = {
          id: newUuid,
          base_unit: true,
          storage_unit: this.whStorageUnit,
          storage_amount: 1 * this.multiplier,
          is_selling_item: this.whSellingItem ? this.whSellingItem : false,
          default_sale: this.whDefaultSale ? this.whDefaultSale : false,
          default_store: this.whDefaultStore ? this.whDefaultStore : false
        }

        units.push(baseUnit)
        mpUnits.set(newUuid, baseUnit)

        if (this.whDefaultSale !== undefined && this.whDefaultSale !== false) {
          defaultSale = newUuid
        }

        if (this.whDefaultStore !== undefined && this.whDefaultStore !== false) {
          defaultStore = newUuid
        }

        this.newUnits.forEach((unit) => {
          const newUuid = uuidv4()

          if (unit.defaultSale !== undefined && unit.defaultSale !== false) {
            defaultSale = newUuid
          }

          if (unit.defaultStore !== undefined && unit.defaultStore !== false) {
            defaultStore = newUuid
          }

          const temp = {
            id: newUuid,
            storage_unit: unit.customItemUnit,
            storage_amount: (parseFloat(unit.customItemAmount.replace(',', '.')) * this.multiplier),
            is_selling_item: unit.sellingItem ? unit.sellingItem : false,
            default_sale: unit.defaultSale ? unit.defaultSale : false,
            default_store: unit.defaultStore ? unit.defaultStore : false,
            base_unit: false
          }

          units.push(temp)
          mpUnits.set(newUuid, temp)
        })

        // Trenutno zakomentirano jer defaultna prodajna nije obavezna
        // if (defaultSale === '') {
        //   alert('Defaultna prodajna jedinica je obavezna')

        //   this.submitting = false
        //   return
        // }

        if (this.addedOverWh === true && defaultStore === '') {
          this.showMsgBox({
            text: 'Zadana skladišna jedinica nije odabrana. Molimo odaberite zadanu skladišnu jedinicu kako bi nastavili.',
            actions: ['cancel'],
            cancelBtnText: 'OK',
            color: 'error'
          })
          this.submitting = false
          return
        }

        const whUnit = {
          base_unit: newUuid,
          default_sale: defaultSale,
          default_store: defaultStore,
          units: units
        }

        payload.action.params.warehouse_units = whUnit

        if (this.norms !== undefined && this.norms.length > 0) {
          let components = {}

          components = this.norms.reduce((components, it) => {
            return Object.assign(components, {
              [it.itemId]: {
                item_id: it.itemId,
                item_name: it.item.name,
                custom_unit_id: it.customUnitId ? it.customUnitId : '',
                custom_unit: !!(it.customUnitId !== '' && it.customUnitId !== undefined),
                quantity: typeof it.quantity === 'string' ? (parseFloat(it.quantity.replace(',', '.')) * this.multiplier) : it.quantity * this.multiplier
              }
            })
          }, {})

          payload.action.params.norm = {
            type: this.selectedNormType,
            components: components
          }
        }
      }

      if (this.barcode && this.barcode !== '') {
        this.barcode = this.barcode.trim()
        const barcodes = this.barcode.split(',')
        if (barcodes[0] !== '') {
          payload.action.params.barcode = barcodes[0]
        }

        payload.action.params.barcodes = barcodes
      }

      if (this.itemSuppliers !== undefined && this.itemSuppliers.length > 0) {
        payload.action.params.suppliers = []
        var allSupps = []
        this.itemSuppliers.forEach(supp => {
          if (allSupps.length === 0) {
            allSupps.push({ id: supp.id, name: supp.name, item_code: supp.item_code, item_codes: [supp.item_code] })
          } else if (allSupps.length > 0) {
            const idx = allSupps.findIndex(s => s?.id === supp.id)

            if (idx > -1) {
              allSupps[idx].item_codes.push(supp.item_code)
            }
          }
        })

        allSupps.forEach(supp => {
          payload.action.params.suppliers.push({ id: supp.id, name: supp.name, item_code: supp.item_code, item_codes: supp.item_codes })
        })
      }

      var companyitemApi = functions.httpsCallable('companyitemapi')

      var that = this
      companyitemApi({
        action: {
          operation: payload.action.operation,
          entity: payload.action.entity,
          resource_id: payload.action.resource_id,
          params: payload.action.params
        }
      })
        .then((result) => {
          if (result.data.code === 200) {
            this.submitting = false
            that.submitting = false
            that.$refs.imageUpl.reset()
            that.$refs.addItemForm.reset()
            that.close()
            this.$nextTick(() => {
              this.selectedPrices = []
              this.visible = false
            })
          } else {
            this.showMsgBox({
              text: result.data.message ?? 'Dogodila se pogreška.',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            that.submitting = false
          }
        })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    save () {},
    cancel () {}

  }

}
</script>
<style scoped>
.th {
  vertical-align: top;
}
</style>
