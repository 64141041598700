<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="640"
    style="z-index: 6"
  >
    <v-form ref="addNormForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Normativ</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container class="pl-0 pr-0">
            <v-col cols="12" class="pt-3 pb-0 mb-0">
              <v-col class="d-flex justify-center pa-3 pb-0">
                <v-autocomplete
                  dense
                  outlined
                  label="Artikl"
                  v-model="normItemSelected"
                  item-text="name"
                  return-object
                  :items="items"
                >
                </v-autocomplete>
                <v-btn
                  class="white--text okButton mx-2"
                  height="40"
                  @keyup.enter="addNorm"
                  @click.stop="addNorm"
                  dark
                  >Dodaj</v-btn
                >
              </v-col>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-flex v-for="(item, nkey) in norms" :key="nkey">
                <v-row class="pl-3 pr-3 pt-4">
                  <v-col cols="7">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Sastojak"
                      :value="item.item.name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Količina"
                      outlined
                      dense
                      hide-details
                      v-model="item.quantity"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="mt-2"
                      style="z-index: 8"
                      bottom
                      dense
                      right
                      plain
                      label=""
                      @click="removeNorm(item)"
                      >Ukloni</v-btn
                    >
                  </v-col>
                </v-row>
              </v-flex>
              <v-row class="pl-3 pr-3">
                <v-col>
                  <v-radio-group v-model="selectedNormType" class="mt-0">
                    <v-radio
                      dense
                      v-for="normType in normTypes"
                      :key="normType.value"
                      :value="normType.value"
                      :label="normType.name"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
export default {
  name: 'AddCategory',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    name: undefined,
    submitting: false,
    picture: '',
    pictureDirty: false,
    rules: {
      req: rules.req('Required field')
    },
    items: [],
    currentItem: {},
    normItemSelected: undefined,
    norms: [],
    selectedNormType: 'sale'
  }),
  computed: {
    normTypes () {
      return [
        { name: 'Skini sastojke prilikom prodaje', value: 'ON_SALE' },
        { name: 'Skini sastojke prilikom kreiranja artikla', value: 'ON_CREATE' }
      ]
    }
  },
  methods: {
    removeNorm (rmItem) {
      this.norms = this.norms.filter(norm => {
        if (norm.item.id !== rmItem.item.id) {
          return norm
        }
      })
    },
    addNorm () {
      if (!this.normItemSelected) { return }
      let found = false
      if (this.norms.length > 0) {
        this.norms.forEach(norm => {
          if (norm.item.id === this.normItemSelected.id) {
            found = true
          }
        })
      }

      if (!found) {
        const newNorm = {
          item: this.normItemSelected,
          quantity: 0,
          itemId: this.normItemSelected.id
        }

        if (this.normItemSelected.id.includes('.')) {
          newNorm.customUnitId = this.normItemSelected.id.split('.')[1]
          newNorm.itemId = this.normItemSelected.id.split('.')[0]
        }

        this.norms.push(newNorm)
        this.norms.reverse()
      }
      this.normItemSelected = ''
    },
    async getCompItems () {

    },
    async open (currentItem, allItems, existing = undefined) {
      const items = []

      this.currentItem = currentItem
      const query = await df
        .collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('status', '==', 'OK')

      query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const item = doc.data()

            if (item.warehouse_units !== undefined && item.warehouse_units.units !== undefined) {
              Object.keys(item.warehouse_units.units).forEach(key => {
                const newItem = clone(item)
                newItem.name = item.warehouse_units.units[key].storage_unit
                newItem.prices = item.warehouse_units.units[key].prices
                // if (item.warehouse_units.units[key].storage_unit && item.warehouse_units.units[key].storage_unit !== '') {
                //   newItem.name = item.warehouse_units.units[key].storage_unit
                // } else {
                //   newItem.name = item.name
                // }

                var stUnit = item.warehouse_units.units[key].storage_unit
                if (stUnit === '.kom') {
                  stUnit = ''
                }

                newItem.name = item.name + ' ' + stUnit
                newItem.id = item.id + '.' + item.warehouse_units.units[key].id

                if (this.currentItem && this.currentItem.id !== newItem.id) {
                  items.push(newItem)
                } else if (!this.currentItem) {
                  items.push(newItem)
                }
              })
            } else {
              if (this.currentItem && this.currentItem.id !== item.id) {
                items.push(item)
              } else if (!this.currentItem) {
                items.push(item)
              }
            }
          })
          this.items = clone(items.sort((a, b) => a.name.localeCompare(b.name)))
        })

      this.visible = true
      // Zakomentirano jer je stavljeno da se ponovno dohvati sve prilikom otvaranja, ako ne bude trebalo do 30.05 obrisi
      // allItems.forEach(item => {
      //   if (item.warehouse_units !== undefined && item.warehouse_units.units !== undefined) {
      //     Object.keys(item.warehouse_units.units).forEach(key => {
      //       const newItem = clone(item)
      //       newItem.name = item.warehouse_units.units[key].storage_unit
      //       newItem.prices = item.warehouse_units.units[key].prices
      //       newItem.name = item.name + ' ' + item.warehouse_units.units[key].storage_unit
      //       newItem.id = item.id + '.' + item.warehouse_units.units[key].id

      //       this.items.push(newItem)
      //     })
      //   } else {
      //     this.items.push(item)
      //   }
      // })

      if (existing !== undefined && existing.length > 0) {
        existing.forEach(comp => {
          comp.item.name = comp.item.name ? comp.item.name : ''

          // Hack za razlike u imenovanju
          if (comp.item.name === '') {
            comp.item.name = comp.item.item_name ? comp.item.item_name : ''
          }

          comp.item.id = comp.item.id ? comp.item.id : ''

          if (comp.item.id === '') {
            comp.item.id = comp.item.item_id ? comp.item.item_id : ''
          }

          const newNorm = {
            item: comp.item,
            quantity: comp.quantity.toString().replace('.', ','),
            itemId: comp.item.id
          }

          if (comp.item.custom_unit === true) {
            newNorm.customUnitId = comp.item.custom_unit_id
            newNorm.itemId = comp.item.item_id
          }

          if (comp.customUnitId && comp.customUnitId !== '') {
            newNorm.customUnitId = comp.customUnitId
            newNorm.itemId = comp.itemId
          }

          this.norms.push(newNorm)
        })
      }
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.norms = []
      this.normItemSelected = undefined
    },
    submit () {
      const that = this

      if (this.norms.some(norm => norm.quantity === '' || parseFloat(norm.quantity.replace(',', '.')) === 0)) {
        this.showMsgBox({ text: 'Količina ne smije biti 0.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        return
      }

      if (!this.$refs.addNormForm.validate()) return

      // if (this.norms && this.norms.length > 0) {
      //   this.norms.forEach((norm, key) => {
      //     console.log('norm, key', norm, key)
      //     if (norm.customUnitId && norm.customUnitId !== '') {
      //       norm.quantity = parseFloat(norm.quantity) * norm.item.warehouse_units.units[norm.customUnitId].storage_amount
      //     }
      //   })
      // }

      that.$emit('add', { norms: this.norms, normType: this.selectedNormType })
      this.close()
    }
  }
}
</script>
